import React, { ReactElement, useEffect, useState } from "react"
import logo from "../images/logo.png"
import avatar from "../images/avatar.svg"
import star from "../icons/star.svg"
import starFilled from "../icons/star_filled.svg"
import Rating from "react-rating"
import { FieldArray, Formik } from "formik"
import axios from "axios"
import qs from "qs"



function Index(props: any): ReactElement {
  let API_URL: string;
  const [overallRating, setOverallRating] = useState(0)
  const [submitted, setSubmitted] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [data, setData] = useState()
  const params = qs.parse(props.location.search, { ignoreQueryPrefix: true })


  useEffect(() => {
    API_URL = props.location.href.includes("localhost") ?
      "http://localhost:5001/digitalny-poradca/europe-west1/" :
      "https://europe-west1-digitalny-poradca.cloudfunctions.net/"
    if (data == null) {
      if (params.rating != null) setOverallRating(Number(params.rating / 20))
      axios.get(`${API_URL}get_feedback_details?id=${params.id}&email=${params.email}`)
        .then(function(response) {
          setData(response.data)
          if (response.data.submitted) {
            setSubmitted(true)
            setOverallRating(response.data.submitted.overall_rating / 20)
          }
        })
        .catch(function(error) {
          console.error(error)
        })
    }
  })

  const submitFeedback = (values: any) => {
    const feedback = {
      id: params.id,
      email: params.email,
      overall_rating: overallRating * 20,
      testimonial: values.testimonial,
      contacts: values.contacts,
    }
    //if (feedback.contacts.length == 0 && confirm("Naozaj chcete odoslať hodnotenie bez odporúčania služby?")) {}
    axios.post(`${API_URL}submit_feedback`, { data: JSON.stringify(feedback) })
      .then(function(response) {
        setSubmitting(false)
        setSubmitted(true)
      })
      .catch(function(error) {
        setSubmitting(false)
        setSubmitted(false)
      })
  }

  return (
    <div className="container mx-auto">
      <div>
        <nav className="menu flex items-center justify-between flex-wrap bg-teal-500 p-6">
            <span className="font-semibold text-lg md:text-2xl tracking-tight">
              Hodnotenie spokojnosti
            </span>
          <img src={logo} alt="Finix" className="h-12"/>
        </nav>
      </div>
      {submitting &&
      <div className="text-center pt-40">
        <h2>Odosiela sa...</h2>
      </div>}
      {submitted &&
      <div className="text-center pt-40">
        <h2 className="text-3xl pb-6 font-semibold">Ďakujeme,</h2>
        <p className="text-lg pb-10">Spätná väzba bola úspešne odoslaná.</p>
        <p className="text-3xl pb-2">{overallRating * 20} %</p>
        <Rating
          className="mb-8"
          initialRating={overallRating}
          readonly={true}
          emptySymbol={<img src={star} className="icon"/>}
          fullSymbol={<img src={starFilled} className="icon"/>}
        />
      </div>}
      {data == null ?
        <div className="text-center pt-40">
          <h2 className="text-xl">Načítava sa...</h2>
        </div>
        :
        <div className="container max-w-xl mx-auto mt-10 text-center" hidden={(submitting || submitted)}>
          <div className="rounded overflow-hidden text-center">{/* shadow-xl*/}
            <img
              className="w-1/3 rounded-lg mx-auto mb-3"
              src={data.expert.profile_photo}
              alt=""
            />
            <div className="font-semibold text-gray-700 text-3xl mb-5">{data.expert.name}</div>
            <div className="font-normal text-3xl mb-16">
              Ďakujem Vám za využitie mojich služieb.
            </div>
          </div>
          <h2 className="text-2xl font-bold mb-1">Hodnotenie spokojnosti</h2>

          <h2 className="text-lg font-normal mb-3">Ohodnoťte prosím Vašu spokojnosť s mojou prácou</h2>
          <Rating
            className="mb-8"
            initialRating={overallRating}
            onChange={(rating) => setOverallRating(rating)}
            emptySymbol={<img src={star} className="icon"/>}
            fullSymbol={<img src={starFilled} className="icon"/>}
          />
          <Formik
            initialValues={{ testimonial: "", contacts: [{ name: "", contact: "" }, { name: "", contact: "" }] }}
            validate={values => {
              const errors: any = {}
              if (!values.testimonial || values.testimonial.length < 5) {
                errors.testimonial = "Napíšte prosím pár slov"
              }
              return errors
            }}
            onSubmit={(values) => {
              setSubmitting(true)
              submitFeedback(values)
            }}
          >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
              <form onSubmit={handleSubmit}>
                <h2 className="text-2xl font-bold mb-1">Referencia</h2>
                <p className="text-lg font-normal mb-3">Napíšte mi čo sa Vám páčilo, čo by som mohol ešte vylepšiť</p>

                <textarea className="block w-full mb-1 border-2 rounded-lg p-3"
                          name="testimonial"
                          rows={5}
                          required={true}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.testimonial}
                ></textarea>
                <p className="mb-3 text-red-600">{errors.testimonial && touched.testimonial && errors.testimonial}</p>


                <h2 className="text-2xl font-bold mb-3">Odporúčanie služby</h2>

                <p className="text-gray-700 text-base text-justify mb-3">
                  Mojou odmenou od Vás, ak ste boli na 100% spokojný, je odporúčanie 2 Vašich dobrých známych, ktorým
                  v
                  krátkosti odprezentujem, ako im viem pomôcť.
                </p>
                <div className="flex flex-col mb-10">
                  <FieldArray
                    name="contacts"
                    render={arrayHelpers => (
                      <div>
                        {values.contacts && values.contacts.length > 0 && (
                          values.contacts.map((contact, index) => (
                            <div key={index}>
                              <AvatarRow index={index}
                                         onChange={handleChange}
                                         onBlur={handleBlur}
                                         value={values.testimonial}
                              />
                            </div>
                          ))
                        )}
                        {values.contacts.length > 2 && <button
                          type="button"
                          className="icon-button outline mr-4"
                          onClick={() => arrayHelpers.remove(values.contacts.length - 1)}
                        >
                          –
                        </button>}
                        <button
                          type="button"
                          className="icon-button"
                          onClick={() => arrayHelpers.push({ name: "", contact: "" })}
                        >
                          +
                        </button>
                      </div>
                    )}
                  />
                </div>

                <button className="primary-button text-white font-bold py-3 px-10 rounded"
                        type="submit"
                        disabled={isSubmitting}>
                  Odoslať
                </button>
              </form>
            )}
          </Formik>
          < a href="https://finix.sk/ochrana-osobnych-udajov/">
            <p className="pt-12 pb-6">Politika ochrany osobných údajov</p>
          </a>
        </div>}
    </div>
  )
}

const AvatarRow = (props: { index: number, onChange: any, onBlur: any, value: any }) => {
  return (
    <div className="flex flex-row justify-end content-end pt-4 pb-4">
      <img
        className="rounded-lg mx-auto mb-3 mr-6 pt-2"
        src={avatar}
        alt=""
      />
      <div className="flex-1 flex flex-col">
        <input className="block w-full border-2 rounded p-2 mb-2"
               type="text"
               placeholder="Meno"
               name={`contacts.${props.index}.name`}
               onBlur={props.onBlur}
               value={props.value.name}
               onChange={props.onChange}/>
        <input className="block w-ful border-2 rounded p-2"
               type="text"
               placeholder="Telefónne číslo"
               name={`contacts.${props.index}.contact`}
               onBlur={props.onBlur}
               value={props.value.contact}
               onChange={props.onChange}
        />
      </div>
    </div>
  )
}

export default Index
